<template>
<v-col class="py-0 mt-n2 pl-0 fullscreen">
    <iframe sandbox="allow-forms allow-scripts allow-same-origin" :src="link" allowfullscreen="allowfullscreen" class="fullscreen"></iframe>
</v-col>
</template>

<script>
/* 
  Name:Library
  Description: Library view is the library page with the iframe for the library import url
*/
export default {
    name: "Library",
    data() {
        return {
            link: "https://academy.newdeal.gr/",
        };
    },
};
</script>

<style lang="scss">
.fullscreen {
    width: 102%;
    height: 100vh;
}
</style>
